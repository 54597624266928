@use 'sass:math';

// Note: the overall strategy for converting pixels to em units is to divide the pixel value by 16px (which was the default em value for a document in our app) and multiply it by 1em
// eg. 16px = calc(16/16 * 1em)



// CSS classes for version 1 keyboard finger images
// TODO: deprecate once transition to V2 of keyboard

.fingerImage {
  position: absolute;
  top: calc(-2/16 * 1em);
  width: calc(334/16 * 1em);
  clip: rect(0em, calc(400/16 * 1em), calc(320/16 * 1em), 0em);
}

.leftHand {
  left: calc(-76/16 * 1em);
}

.rightHand {
  left: calc(218/16 * 1em);
}


.keyboardWidth {
  width: calc(520/16 * 1em);
}

// Since we convert SVGs via SVGR library, the classNames are hard-coded (so CSS modules are not used)
// so :global allows us to define classnames without converting to CSS modules
:global {

  // Each SVG hand should have layers with css classes of either .skin, .shadow, and .nails which can be targeted
  .noSkinTone {
    .skin {
      fill: #FFD767;
      fill-opacity: 0.8;
    }
    .shadow {
      fill: #F1BD4B;
      fill-opacity: 0.4;
    }
    .nails {
      fill: #FFF7DF;
      fill-opacity: 0.5;
    }
  } 

  .lightSkinTone {
    .skin {
      fill: #FCDDCD;
      fill-opacity: 0.8;
    }
    .shadow {
      fill: #F3CAB4;
      fill-opacity: 0.4;
    }
    .nails {
      fill: #fff;
      fill-opacity: 0.5;
    }
  }

  .mediumLightSkinTone {
    .skin {
      fill: #ECBD96;
      fill-opacity: 0.8;
    }
    .shadow {
      fill: #D7A488;
      fill-opacity: 0.4;
    }
    .nails {
      fill: #F5EBE0;
      fill-opacity: 0.5;
    }
  }

  .mediumDarkSkinTone {
    .skin {
      fill: #A06940;
      fill-opacity: 0.8;
    }
    .shadow {
      fill: #86573D;
      fill-opacity: 0.4;
    }
    .nails {
      fill: #FDE3D0;
      fill-opacity: 0.5;
    }
  }

  .darkSkinTone {
    .skin {
      fill: #512D1A;
      fill-opacity: 0.8;
    }
    .shadow {
      fill: #4E3127;
      fill-opacity: 0.4;
    } 

    .nails {
      fill: #C8A891;
      fill-opacity: 0.5;
    }
  }
}

// STARTS HERE
// Each key has a width of 6 units
// Some keys can have a width of more than 6 units

$key-width: calc(34/16 * 1em); // 34
$font-size: calc(14/16 * 1em);
$space-between-keys: calc(3/16 * 1em);
$space-between-rows: calc(3/16 * 1em);

$border-width: calc(1/16 * 1em);
$key-border-radius: calc(4/16 * 1em);

// renamed from keyboard-interactive
.keyboardKey {
  // Global keyword uses allows us to keep normal class names scoped to the above class using the
  // approach described here: 
  // https://stackoverflow.com/questions/40905309/how-to-nest-classes-in-css-modules-and-react/70389583#70389583
  :global {
    // Disable highlighting
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    .key {
      height: $key-width - $border-width;
      margin-right: $space-between-keys;
      margin-bottom: calc(2/16 * 1em);
      // background-color: #fafafa;
  
      -moz-border-radius: $key-border-radius;
      border-radius: $key-border-radius;
      overflow: hidden;
    }
  
    // this class is added when the shadow key, positioned above, it hovered
    .key-hovered {
      background-color: #c3ebfe;
    }
  
    // To help animate
    .key-styling {
      color: #696969;
      border: calc(1/16 * 1em) solid #999;
  
      -webkit-transition: all 80ms ease-out;
      -moz-transition: all 80ms ease-out;
      -ms-transition: all 80ms ease-out;
      -o-transition: all 80ms ease-out;
      transition: all 80ms ease-out;
    }
  
    div.pri {
      font-weight: 500;
      color: #2a2a2a;
      padding-top: calc(($key-width - $font-size - (6/16 * 1em))/ 2 - (3/16 * 1em));
      .digit {
        font-size: $font-size + calc(3/16 * 1em);
      }
    }
  
    div.sec {
      color: #7e7e7e;
      padding-top: calc(($key-width - $font-size - (2/16 * 1em))/2 - (3/16 * 1em));
      .digit { 
        font-size: $font-size - calc(2/16 * 1em);
      }
    }
  
    div.ter {
      color: #bdbdbd;
      padding-top: calc(($key-width - $font-size - (2/16 * 1em))/ 2 - (3/16 * 1em));
      .digit {
        font-size: $font-size - calc(2/16 * 1em);
      }

    }
  
    div.last-key {
      margin-right: 0em;
    }
  
    .letter-key {
    // Previously .key-6
      width: ($key-width - $border-width);
    }
  
    .alt-key {
      // Previously .key-7
      width: calc(($key-width - $border-width) * 1.2);
    }
  
    .backspace-key,
    .tab-key,
    .ctrl-key {
      // Previously .key-8
      width: calc(($key-width - $border-width) * 1.5);
    }
  
    .capslock-key,
    .enter-key {
      // Previously .key-10
      width: calc(($key-width - $border-width) * 1.8);
    }

    .right-shift-key,
    .left-shift-key {
      // Previously .key-12
      width: calc(($key-width - $border-width) * 2.3333);
    }
    
    .space-key{
      // Previously .key-32
      width: calc(($key-width - $border-width) * 6);
    }

    .digit {
      position: relative;
      text-align: center;
    }

  
    .keyboard-row {
      margin-bottom: $space-between-rows;
    }
  
    // Background color of a highlighted (pressed) key 
    div.active {
      // color: white;
      background-color: #3daadf;
      // background-color: #36abe3;
      // opacity: 0.6;
      border-color: #3daadf;
    }
  
    // we add this class in here since our JSX Component only uses this id
    div.shadowActive {
      color: white;
    }

    .shadow-keyboard{
      position: absolute;
      top: 0; // need this to properly position the shadow keyboard as an overlay
      z-index: 10;
    
      // margin-left: 161px;
      margin-bottom: calc(12/16 * 1em);
    
      // Disable highlighting
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  
      // To help animate
      .key-styling {
        border: calc(1/16 * 1em) solid transparent;
  
        -webkit-transition: all 80ms ease-out;
        -moz-transition: all 80ms ease-out;
        -ms-transition: all 80ms ease-out;
        -o-transition: all 80ms ease-out;
        transition: all 80ms ease-out;
      }
    
      div.active {
        color: white;
        background: none;
        border: calc(1/16 * 1em) solid transparent;
      }
    }
  }
}


// ---------------------------------------------------------------------------------------------------------------------------------------
// VERSION 2 
// ------------------------
// Important Notes: We define a single keyboardKey class which is applied to the container
// element, and subclasses are applied conditionally to the elements inside. Notes:
//  1. the .key class contains the styles applied to ALL keys.
//  2. 

// To modify the keys, start with these variables. A few notes:
// 1. Since Figma uses inner borders, we subtract 2x the border width from the width property.
//    i.e. if a key in Figma has width 32px, and an inner border of 2px, CSS should have width 28px, border 2px, resulting
//    in an element with a width (incl. borders) of 32px.
// 2. key-width is currently used as the height, too.
// 
// letter-key
$key-width: calc(32/16 * 1em); // used as height, too
$key-7-width: calc(42/16 * 1em); // alt
$key-8-width: calc(54/16 * 1em); // backspace, tab, ctrl
$key-10-width: calc(60/16 * 1em); // capslock
$key-11-width: calc(63/16 * 1em); // enter
$key-12-width: calc(80/16 * 1em); // rightShift, leftShift
$key-32-width: calc(182/16 * 1em); // space
$space-between-keys: calc(5/16 * 1em);
$space-between-rows: calc(5/16 * 1em);

$border-width: calc(1/16 * 1em);
$key-border-radius: calc(4/16 * 1em);

// the full width of the keyboard - should be 535px
// Note, using only the first row to calculate the width, make sure the rest of the rows are the same value
$full-keyboard-width: $space-between-keys * 11 + ($key-width * 10) + ($key-12-width * 2); 

// CSS classes for version 2 keyboard finger images
.fingerImageContainer {
  position: absolute;
  z-index: 2;
  top: 0px;
  transition-property: top, opacity;
  transition-duration: 0.3s;
  opacity: 1;
}

// if added, will animate the hands fading out (placed on same element as where .fingerImageContainer is located)
// Used with hands showing the next key to press
.hideFingerImageContainer {
  opacity: 0;
}

// if added, will animate the hands sliding down (placed on same element as where .fingerImageContainer is located)
// Used with hands on the homerow
.lowerFingerImageContainer {
  top: 50px;
}

.fingerImageLeft {
  position: absolute;
  top: 0;
  width: calc($full-keyboard-width * 1965 / 3972);
  left:0;
}

.fingerImageRight {
  position: absolute;
  top: 0;
  right: 0;
  width: calc($full-keyboard-width * 2321 / 3972);
}

.keyboardWidthV2 {
  width: $full-keyboard-width;
}

.keyboardKeyV2 {
  box-sizing: border-box;

  // Global keyword uses allows us to keep normal class names scoped to the above class using the
  // approach described here: https://stackoverflow.com/questions/40905309/how-to-nest-classes-in-css-modules-and-react/70389583#70389583
  :global {
    // --------------------------------------------
    // .key contains the styling applied to ALL keys
    // -----------------------------
    .key {
      height: $key-width;
      margin-right: $space-between-keys;
      margin-bottom: $space-between-keys;
      // background-color: #fafafa;
  
      border: calc(1/16 * 1em) solid #D1D1D1;
      background-color: #F4F4F4;
      border-radius: calc(4/16 * 1em); 
      box-shadow: #D1D1D1 calc(-1/16 * 1em) calc(1/16 * 1em);
      -moz-border-radius: $key-border-radius;
      border-radius: $key-border-radius;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
   
    // this class is added when the shadow key, positioned above, it hovered
    .key-hovered {
      background-color: #c3ebfe;
    }

    // Background color of a highlighted (pressed) key
    .active {
      background-color: #0066FF;
      border-color: transparent;
      box-shadow: none;
      .digit-fill{
        color: #FFF;
      }
      .digit-stroke{
        -webkit-text-stroke-color: #004DC1; 
      }
    }

     // To help animate
     .key-styling {
      -webkit-transition: all 80ms ease-out;
      -moz-transition: all 80ms ease-out;
      -ms-transition: all 80ms ease-out;
      -o-transition: all 80ms ease-out;
      transition: all 80ms ease-out;
    }

    // --------------------------------------------
    // text is contained inside .digit
    // all properties related to the position or styling of text go here.
    // -----------------------------
    .digit {
      position: relative;
    }

    // This creates an outline stroke around the font in the center (meaning it's going both in and outside the font)
    // We position the same letter (.digit-fill) without the stroke right on top, giving illusion of an outside stroke effect
    .digit-stroke {
      padding-top: calc(1/16 * 1em);
      -webkit-text-stroke: 0.1em #FFF;
    }

    // this is the font being positioned above .digit-stroke to create illusion of outside stroke effect
    .digit-fill {
      padding-top: calc(1/16 * 1em);
      position: absolute;
      top: 0;
      left: 0;
    }

    .pri {
      color: #2C2C2C;
      font-weight: 500;
      color: #2a2a2a;
      .digit {
        font-size: 1em;
      }
    }
  
    .sec {
      color: #2C2C2C;
      .digit {
        font-size: 0.7em;
      }
    }
  
    .ter {
      color: #2C2C2C;
      .digit {
        font-size: 0.5em;
      }
    }
   
    div.last-key {
      margin-right: 0em;
    }

    // --------------------------------------------
    // Styling of individual keys
    // --------------------------------------------
    .arrow-right-key,
    .arrow-left-key,
    .arrow-up-key,
    .arrow-down-key {
      .digit {
        font-size: 0.5em;
      }
    }

    .arrow-right-key, 
    .arrow-left-key {
      width: $key-width;
    }
    
    .arrow-up-key {
      width: $key-width;
      height: math.div($key-width, 2);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
    }

    .arrow-down-key {
      width: $key-width;
      height: math.div($key-width, 2);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .ctrl-key,
    .alt-key,
    .tab-key,
    .capslock-key,
    .enter-key,
    .left-shift-key,
    .right-shift-key,
    .space-key {
      .digit .digit-stroke, .digit .digit-fill {
            padding: 0em calc(4/16 * 1em);
      }
    }

    .letter-key,
    .ctrl-key {
      width: $key-width;
    }

    .ctrl-key {
      align-items: end;
    }
  
    .alt-key {
      width: $key-7-width;
      align-items: end;
    }
  
    .backspace-key {
      width: $key-8-width;
    }

    .tab-key{
      width: $key-8-width;
      align-items: end;
      justify-content: flex-start;
    }
  
    .capslock-key {
      width: $key-10-width;
      .digit{
        width: 100%;
        height:100%;
      }
      .digit-stroke, .digit-fill {
         display: flex;
         width: 100%;
         height: 100%;
         flex-direction: column;
         justify-content: space-between;
         align-items: flex-start; 
      }
    }

    .enter-key{
      width: $key-11-width;
      align-items: end;
      justify-content: end;
    }
  
    .left-shift-key {
      width: $key-12-width;
      align-items: end;
      justify-content: flex-start;
    }

    .right-shift-key {
      width: $key-12-width;
      align-items: end;
      justify-content: end;
    }
  
    .space-key{ 
      width: $key-32-width;
    }

    // shadow keys are positioned above the interactive keys (and above the hand images)
    .shadow-keyboard{
      position: absolute;
      top: 0; // need this to properly position the shadow keyboard as an overlay
      z-index: 10;
    
      margin-bottom: calc(12/16 * 1em);
    
      // Disable highlighting
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .key {
        background-color: transparent;
        box-shadow: none;
      }

      // To help animate
      .key-styling {
        border: calc(1/16 * 1em) solid transparent;
  
        -webkit-transition: all 80ms ease-out;
        -moz-transition: all 80ms ease-out;
        -ms-transition: all 80ms ease-out;
        -o-transition: all 80ms ease-out;
        transition: all 80ms ease-out;
      }

    }

    // Disable highlighting
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  
  }
}

