.skinToneSettings {
  ul{
    overflow: hidden;
    max-width: 0%;
    transition-property: max-width;
    transition-duration: 0ms;
    transition-delay: 600ms;

    li {
      transition-property: opacity, transform;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1); 
      opacity: 0;
      transform: translateX(-2em);
      .icon {
            width: 1em;
            height: 1em;
            border-radius: 50%;
            transition-property: transform, border-radius;
            transition-duration: 0.2s;
            transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1); 
          }

      &:hover .icon{
          transform: scale(1.4);
          border-radius: 25%;
      }
    }
    li:nth-child(1){
      transition-delay: 0ms;
    }

    li:nth-child(2){
      transition-delay: 100ms;
    }

    li:nth-child(3){
      transition-delay: 200ms;
    }

    li:nth-child(4){
      transition-delay: 300ms;
    }

    li:nth-child(5){
      transition-delay: 400ms;
    }
  }
    
  &.active {
    ul {
      transition-delay: 0ms;
      max-width: 100%;
      li {
        transform: translateX(0)
      }
      li:nth-child(1){
        opacity: 1 !important;
      } 
      li:nth-child(2){
        opacity: 1 !important;
      } 
      li:nth-child(3){
        opacity: 1 !important;
      } 
      li:nth-child(4){
        opacity: 1 !important;
      } 
      li:nth-child(5){
        opacity: 1 !important;
      } 
    }
   
  }

 
}

 /* Tone down the animation if user prefes reduced motion. */
 @media (prefers-reduced-motion) {
  .skinToneSettings {
    ul{
      transition-delay: 0ms;
      li {
        transition-duration: 0s;
        transition-delay: 0s!important;
        .icon {
              transition-duration: 0s;
            }
        &:hover .icon{
            transform: scale(1);
            border-radius: 50%;
        }
      }
    }
  }
}
